@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  font-size: 30px;
  composes: animation;
  animation-delay: 0.5s;
  width: 100%;
  text-align: center;
}

.heroMainTitleDesktop {
  composes: heroMainTitle;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.heroMainTitleMobile {
  composes: heroMainTitle;
  font-size: 24px;
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  text-align: center;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  display: block;
  animation-delay: 0.8s;
  width: 260px;
}